function LoadMyNavMenuStuff() {
    $(document).on("click", function (event) {
        if ($(event.target).closest(".nav-container").length === 0) {
            // $("body").removeClass("overlay");
            // $("body").addClass("nooverlay");
            $(this).removeClass("c-main-nav--selected");
            $(".nav-container .c-main-nav__wrapper").removeClass("c-main-nav--open-all");
        }
    });

    $(document).on("click", function (event) {
        if ($(event.target).closest(".nav-logo").length === 0) {
            // $("body").removeClass("overlay");
            // $("body").addClass("nooverlay");
            $(this).removeClass("c-main-nav--selected");
            $(".nav-logo .c-main-nav__wrapper").removeClass("c-main-nav--open-all");
        }
    });

    $(document).on("click", function (event) {
        if ($(event.target).closest(".hassubmenu-waffle").length === 0) {
            // $("body").removeClass("overlay");
            // $("body").removeClass("nooverlay");
            $(this).removeClass("c-main-nav--selected");
            $(".header-waffle-menu-container .c-main-nav__wrapper").removeClass("c-main-nav--open-all");
        }
    });

    $(".nav-container .u-icon-menu").click(function () {
        // $("body").toggleClass("overlay");
        // $("body").toggleClass("nooverlay");
        $(this).toggleClass("c-main-nav--selected");
        $(".nav-container .c-main-nav__wrapper").toggleClass("c-main-nav--open-all");
    });

    $(".nav-logo .u-icon-menu").click(function () {
        // $("body").toggleClass("overlay");
        // $("body").toggleClass("nooverlay");
        $(this).toggleClass("c-main-nav--selected");
        $(".nav-logo .c-main-nav__wrapper").toggleClass("c-main-nav--open-all");
    });

    $(".hassubmenu-waffle").click(function () {
        // $("body").toggleClass("overlay");
        // $("body").toggleClass("nooverlay");
        $(this).toggleClass("c-main-nav--selected");
        $(".header-waffle-menu-container .c-main-nav__wrapper").toggleClass("c-main-nav--open-all");
    });

    $(".c-main-nav__menu-item .hassubmenu").click(function () {
        $(this).toggleClass("open");
        $(this).next().slideToggle("slow");
    });

    $(document).on("click", function(event){
        if(!$(event.target).closest(".header-utility-nav-container section a").length) {
            $(".header-utility-nav-container section ul").slideUp("slow");
        }
    });

    $(document).on("click", function(event){
        if(!$(event.target).closest(".utility-nav section a").length) {
            $(".utility-nav section ul").slideUp("slow");
        }
    });

    $(document).on("click", function(event){
        if(!$(event.target).closest("section.commodity-toggle section").length) {
            $("section.commodity-toggle ul").slideUp("slow");
        }
    });

    $("section.notifications a").click(function () {
        $(this).toggleClass("open");
        $(this).next().slideToggle("slow");
        $('section.profile ul').slideUp('slow');
        $('section.settings ul').slideUp('slow');
        $('section.commodity-toggle ul').slideUp('slow');
    });

    $("section.alerts a").click(function () {
        $(this).toggleClass("open");
        $(this).next().slideToggle("slow");
        $('section.profile ul').slideUp('slow');
        $('section.settings ul').slideUp('slow');
        $('section.commodity-toggle ul').slideUp('slow');
    });

    $("section.profile a").click(function () {
        $(this).toggleClass("open");
        $(this).next().slideToggle("slow");
        $('section.alerts ul').slideUp('slow');
        $('section.notifications ul').slideUp('slow');
        $('section.settings ul').slideUp('slow');
        $('section.commodity-toggle ul').slideUp('slow');
    });

    $("section.settings a").click(function () {
        $(this).toggleClass("open");
        $(this).next().slideToggle("slow");
        $('section.alerts ul').slideUp('slow');
        $('section.notifications ul').slideUp('slow');
        $('section.profile ul').slideUp('slow');
        $('section.commodity-toggle ul').slideUp('slow');
    });

    $("section.commodity-toggle section").click(function () {
        //$(this).toggleClass("open");
        $(this).next().slideToggle("slow");
        $('section.alerts ul').slideUp('slow');
        $('section.notifications ul').slideUp('slow');
        $('section.profile ul').slideUp('slow');
        $('section.settings ul').slideUp('slow');
        $(".nav-logo .c-main-nav__wrapper").removeClass("c-main-nav--open-all");
    });
}

$(document).ready(function(){
    LoadMyNavMenuStuff()
});



