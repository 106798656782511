//Dependencies: jquery and jquery-modals
(function (factory) {
  if (typeof define === 'function' && define.amd) {
      // AMD. Register as an anonymous module.
      define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
      // Node/CommonJS
      module.exports = function( root, jQuery ) {
          if ( jQuery === undefined ) {
              // require('jQuery') returns a factory that requires window to
              // build a jQuery instance, we normalize how we use modules
              // that require this pattern but the window provided is a noop
              // if it's defined (how jquery works)
              if ( typeof window !== 'undefined' ) {
                  jQuery = require('jquery');
              }
              else {
                  jQuery = require('jquery')(root);
              }
          }
          factory(jQuery);
          return jQuery;
      };
  } else {
      // Browser globals
      factory(jQuery);
  }
}(function ($) {
  $.fn.easyFileUploader = function(options) {
    const uid =  Date.now();
    const modalId = `fileUploader_${uid}`;
    const OGElementId = $(this).attr('id');

    // Clean up any old file uploader modals
    $('.file-uploader-modal').remove();

    const settings = $.extend({
      heading: 'Upload File(s)',
      description: '',
      returnToModalId: '',
      btnText: 'Attach',
      fileNameId: ''
    }, options);

    const allowedExt = $(this).prop('accept');
    const multiple = $(this).prop('multiple');

    //If Editing a file name is supported
    let inputFacade = '';
    let tempFileNameInput  = '';
    if(!!settings.fileNameId ){
       inputFacade = `<input type="hidden" id="${settings.fileNameId}" />`;
       tempFileNameInput  = `<label>File name</label><input id="tempFileNameInput" type="text" name="File Name" /><div id="temFileNameInputError" style="display: none;" class="error u-bg--red-orange u-text--white u-padding-1">File Name is required if file(s) selected.</div>`;

      // If exists, remove existing
      $(`#${settings.fileNameId}`).remove();
    }
    
    const buttonFacade = `<a id="buttonFacade" href="#${modalId}" class="modal-link btn">${settings.btnText}</a><div id="fileNames"><small>Allowed: ${allowedExt}</small></div>${inputFacade}`;

    const tempFileInput = `<input required type="file" id="tempFileInput" accept="${allowedExt}" style="width:1px; height:1px; opacity:0;overflow:hidden;position:absolute;" ${multiple} />`;
  
    const tempFileLabel = `<a id="tempFileInputLabel" class="u-text-center"><h5>Choose file(s) <em>or drag them here</em>.</h5></a>`;

    const dragDropContainer = `<div id="dragDropContainer" class="box u-bg--concrete u-padding-2 u-text-center u-border-solid u-border-1 u-border-color--iron">
      <div class="u-border-dashed u-border-color--gray-chateau u-text-center u-padding-top-4 u-padding-bottom-3">
        <div class="upload-icon">
            <span class="u-icon-upload u-text--gray-chateau" style="font-size:22px;"></span>
            <span class="u-hidden">Upload File</span>
        </div>
        ${tempFileInput}
        ${tempFileLabel}
        <div class="u-margin-top-2"><a id="clearFiles" class="box__restart" role="button">Clear All Files?</a></div>
      </div>
      
    </div>`;  

    const buildTempFilesModal = `<section id="${modalId}" class="file-uploader-modal modal basic-modal basic-modal-medium" style="display: none;">
      <h1>${settings.heading}</h1>
      <p>${settings.description}</p>
  
      <section class="flexbox-grid one-column u-margin-top-4">
          <div class="grid-item">
            ${dragDropContainer}
            ${tempFileNameInput}
        </div>
      </section>
  
      <div class="btn-container flex-center">
          <a class="btn btn-alt-1 modal-link" href="#${!!settings.returnToModalId ? settings.returnToModalId: ''}" rel="modal:close">Cancel</a>
          <a id="updateFilesBtn" class="btn modal-link" href="#${!!settings.returnToModalId ? settings.returnToModalId: ''}" rel="modal:close">Update</a>
      </div>
      <a rel="modal:close" class="close-modal ">Close</a>
    </section>`;
    
    const clearFiles = function(){
      $(`#${modalId} #${OGElementId}`).val('');
      $(`#${modalId} #tempFileInput`).val('');
      $(`#${modalId} #tempFileNameInput`).val('');
      
    }

    const showHideClearFiles = function(){
      if($(`#${modalId} #tempFileInput`).length > 0 && $(`#${modalId} #tempFileInput`)[0].files.length > 0){
        $(`#${modalId} #clearFiles`).show();
      }else{
        $(`#${modalId} #clearFiles`).hide()
      }
    }

    const getFileNames = function(files){
      let namesArray = [];
      for (const file of files) {
        namesArray.push(file.name)
      }
      return namesArray;
    }

    const showFileNameOrInstructions = function(){
      if($(`#${modalId} #tempFileInput`).length > 0 && $(`#${modalId} #tempFileInput`)[0].files.length > 0){
        $(`#${modalId} #tempFileInputLabel`).html(getFileNames($(`#${modalId} #tempFileInput`)[0].files).join(', '));
      }else{
        $(`#${modalId} #tempFileInputLabel`).html(`<h5>Choose file(s) <em>or drag them here</em></h5>`);
      }
    }

    const updateTempFileNameInput = function(){
      if(!multiple && !!settings.fileNameId){
        $(`#${modalId} #tempFileNameInput`).val(getFileNames($(`#${modalId} #tempFileInput`)[0].files));
      }
    }
    
    // Add Elements
    $(this).after(buttonFacade);
    $(buildTempFilesModal).appendTo('body'); 

    // Attach Events
    $(document).on("change", `#${modalId} #tempFileInput`, function() {
      showHideClearFiles();
      showFileNameOrInstructions();
      updateTempFileNameInput();
    });
  
    $(`#${modalId} #clearFiles`).on('click', function(){
      clearFiles();
      showHideClearFiles();
      showFileNameOrInstructions();
      $(`#${modalId} #temFileNameInputError`).hide();
    }) 

    $('#'+modalId).on('modal:open', function (e) {
      showHideClearFiles();
      showFileNameOrInstructions();
    });

    const updateTempFileInput = function(files){
      $(`#${modalId} #tempFileInput`)[0].files = files;
      $(`#${modalId} #tempFileInput`).change();
    }

    $(`#${modalId} #tempFileInputLabel`).on({
      'click': function(e){
        e.preventDefault();
        $(`#${modalId} #tempFileInput`).click()
      }
    });
    
    // When Update Button is clicked
    $(`#${modalId} #updateFilesBtn`).on('click',  function(e){
      const files = $(`#${modalId} #tempFileInput`)[0].files;
      $('#'+OGElementId)[0].files = files;

      // Update file names and button text
      const namesArray = getFileNames(files);
      let fileNameTemplate = ``;
      
      // Update File Name input if exists
      if(!!settings.fileNameId && $(`#${settings.fileNameId}`).length > 0){

        $(`#${settings.fileNameId}`).val($(`#${modalId} #tempFileNameInput`).val())

        if(!!$(`#${modalId} #tempFileNameInput`).val()){
          fileNameTemplate = `<small>${$(`#${modalId} #tempFileNameInput`).val()}</small>`;
          $(`#${modalId} #temFileNameInputError`).hide();
        }else{
          // if fileName is supported and file(s) are provided, but tempFileNameInput has been deleted by user 
          if(files.length > 0){
            $(`#${modalId} #temFileNameInputError`).show();
            e.preventDefault();
            e.stopPropagation();
          }else{
            $(`#${modalId} #temFileNameInputError`).hide();
          }
          fileNameTemplate = `<small>Allowed: ${allowedExt}</small>`;
        }
      }else{
        // Otherwise just use file names from files
        if(namesArray.length > 0){
          fileNameTemplate = `<small>${namesArray.join(', ')}</small>`;
        }else{
          fileNameTemplate = `<small>Allowed: ${allowedExt}</small>`;
        }
      }
      $(`#fileNames`).html(fileNameTemplate);

      // Update OG Button Text
      $(`#buttonFacade`).html(`${namesArray.length} Attached`);
       
    });

    // Drag and Drop    
    $(`#${modalId} #dragDropContainer`).on('drag dragstart dragend dragover dragenter dragleave drop', function(e){
      // preventing the unwanted behaviours
      e.preventDefault();
      e.stopPropagation();
    })

    $(`#${modalId} #dragDropContainer`).on('dragover dragenter', function(e){
      $(`#${modalId} #dragDropContainer`).addClass('is-dragover');
    })

    $(`#${modalId} #dragDropContainer`).on('dragleave dragend drop', function(e){
      $(`#${modalId} #dragDropContainer`).removeClass('is-dragover');
    })

    $(`#${modalId} #dragDropContainer`).on('drop', function(e){
      droppedFiles = e.originalEvent.dataTransfer.files; // the files that were dropped
      updateTempFileInput( droppedFiles );
    })

    // Update style on existing and temp file input
    const css = {width: '1px', height: '1px', opacity: 0, overflow:'hidden', position: 'absolute'};
    const cssKeys = Object.keys(css);
    for(i=0; i < cssKeys.length; i++){
      $(this).css(cssKeys[i], css[cssKeys[i]])
    }

    return this;
  };
}));
