$(document).ready(function(){
    $(".grid-list-view-toggles div.grid-view").click(function () {
        $(this).addClass("active");
        $(".grid-list-view-toggles div.list-view").removeClass("active");
        $(".five-column").removeClass("view-list");
    });

    $(".grid-list-view-toggles div.list-view").click(function () {
        $(this).addClass("active");
        $(".grid-list-view-toggles div.grid-view").removeClass("active");
        $(".five-column").addClass("view-list");
    });

    $(".folder-view-link").click(function () {
        $(".list-folder-view-toggle-container .folder-view").addClass("active");
        $(".folder-view-link").addClass("active");
        $(".list-folder-view-toggle-container .list-view").removeClass("active");
        $(".list-view-link").removeClass("active");
    });

    $(".list-view-link").click(function () {
        $(".list-folder-view-toggle-container .list-view").addClass("active");
        $(".list-view-link").addClass("active");
        $(".list-folder-view-toggle-container .folder-view").removeClass("active");
        $(".folder-view-link").removeClass("active");
    });
});