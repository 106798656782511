function filterMenuOpen(e) {
        
  if (e.field.includes('Date')) {
    var filterDropDown = e.container.find("select[data-role='dropdownlist']").last().data("kendoDropDownList");
    filterDropDown.select(1);
    filterDropDown.trigger("change");
  } else {
    //Rename null to Is Empty
    const label = e.container.find("input[value='null']").parent();
    const input = label.find("input");
    label.empty();
    label.append(input);
    label.append("Is Empty")

    //Remove select all
    const selectAll = e.container.find("input[value='Select All']").parent();
    selectAll.remove();
  }
}