$(document).ready(function(){
    $(".add-new").change(function(){
        var id = $(this).find("option:selected").attr("class");
      
        switch (id){
            case "add-new-option":
                $(".add-new").removeClass("active");
                $(".add-new-input").addClass("active");
                $(".add-new-close").addClass("active");
            break;
        }
    });

    $(".add-new-close").click(function(){
        $(".add-new").addClass("active");
        $(".add-new").val('empty')
        $(".add-new-input").removeClass("active");
        $(".add-new-close").removeClass("active");
    });
});