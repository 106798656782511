$(function () {
    //Bind Menus for normal html
    bindMenus();
});

//Separate function available for anything that needs to bind after an event. i.e. on Kendo grid .Events(events => events.DataBound("bindMenus"))
function bindMenus(){

    const links = document.getElementsByClassName('u-menu-link')
    let bound = false;

    for (let item of links) {
        const event = $._data(item, "events");
        if(!!event && event.hasOwnProperty('click'))
            continue;
        
        $(item).on('click',function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.u-menu').slideUp();
            const offsetY = $(this).offset().top;
            const offsetX = $(this).offset().left;
    
            $('#kMenu-' + this.dataset.id).css({ top: offsetY+30 + 'px', left: offsetX-115 + 'px'  });
            $('#kMenu-' + this.dataset.id).slideToggle(200);
        })
        bound = true;
    }

    if(bound){
        $(window).on('scroll', function () {
            $('.u-menu').slideUp();
        });
        $(document).on('click', function() {
            $('.u-menu').slideUp();
        });
    }
    
}