$(function(){
    $('#planting_setup a[data-edit]').click(function(){
        event.preventDefault();
        
        var parent = $(this).parents('tr');
        var targets = parent.find('.disabled');

        targets.removeClass('disabled');
        
    });

});