$(document).ready(function(){
    // if ($(window).width() < 1200) {
        
    // }
    // else {
    //     $('table.current-application tbody').sortable();
    // }
    
    $('table.draggable tbody').sortable();
    $('#accordion').sortable();

    // $( function() {
    //     $( ".sortable" ).sortable({
    //       revert: true
    //     });
    //     $( ".draggable" ).draggable({
    //       connectToSortable: ".sortable",
    //       //helper: "clone",
    //       revert: "invalid"
    //     });
    //     $( "ul, li, tr" ).disableSelection();
    //   } );
    
    //   let detectTap = false;
    
      
});


