//$(document).on('DOMSubtreeModified',function () {
    $(function () {

        if ($(".datepicker").length) {
            $(".datepicker").datepicker({
                changeMonth: true,
                changeYear: true,
                inline: true,
                altField: "#datep",

            });
        }

        //Crop IQ 1.0 & 2.0 Sepcific
        var dateFormat = "mm/dd/yy",
            from = $( "#CropFilter #FromDate" )
                .datepicker({
                    defaultDate: "+1w",
                    changeMonth: true,
                    numberOfMonths: 3
                })
                .on( "change", function() {
                to.datepicker( "option", "minDate", getDate( this ) );
                }),
            to = $( "#CropFilter #ToDate" ).datepicker({
                defaultDate: "+1w",
                changeMonth: true,
                numberOfMonths: 3
            })
            .on( "change", function() {
                from.datepicker( "option", "maxDate", getDate( this ) );
        });

        function getDate( element ) {
        var date;
        try {
            date = $.datepicker.parseDate( dateFormat, element.value );
        } catch( error ) {
            date = null;
        }
    
        return date;
        }

        //datepickers in modals
        var datePicker = $('.modal .datepicker').datepicker();
        
        $("html").scroll(function() {
            datePicker.datepicker('hide');
            $('.modal .datepicker').blur();  
        });

        $(".blocker").scroll(function() {
            datePicker.datepicker('hide');
            $('.modal .datepicker').blur();  
        });

        $(".max-height").scroll(function() {
            datePicker.datepicker('hide');
            $('.modal .datepicker').blur();  
        });
          
        $(window).resize(function() {
            datePicker.datepicker('hide');
                $('.modal .datepicker').blur();  
        });

        $(window).scroll(function() {
            datePicker.datepicker('hide');
                $('.modal .datepicker').blur();  
        });
    });