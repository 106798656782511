$(document).ready(function(){
    if ($(window).width() < 895) {
        $(".new-subsection-nav-toggle").click(function(){
            $(this).toggleClass("open");
            $(this).next('.new-subsection-nav').slideToggle("slow");
        });
     }
     else {
        
     }
});

