$(document).ready(function(){
    $( "#tabs" ).tabs();


    $(".tabbed-content").tabs(); 
    $(".btn-next-tab").click(function () {
        $( ".tabbed-content" ).tabs( "option", "active", $(".tabbed-content").tabs('option', 'active')+1 );
    });
    $(".btn-prev-tab").click(function () {
        $( ".tabbed-content" ).tabs( "option", "active", $(".tabbed-content").tabs('option', 'active')-1 );
    });

    var alterClass = function() {
        var ww = document.body.clientWidth;
        if (ww < 1024) {
          $('#tabs-Details').addClass('active-mobile');
          $('#tabs-Blocks').addClass('inactive-mobile');
        } else if (ww >= 1024) {
          $('#tabs-Details').removeClass('active-mobile');
          $('#tabs-Blocks').removeClass('inactive-mobile');
        };
      };
      $(window).resize(function(){
        alterClass();
      });
      //Fire it when the page first loads:
      alterClass();
});