// $(function () {
// 	$(document).tooltip({
// 		content: function () {
// 			return $(this).prop('title');
// 		}
// 	});
// });

//this is for when new tooltip styling is implemented, post 03/15/22
//https://jqueryui.com/tooltip/#custom-style
//give the arrow:after a white background and z-index above ui-tooltip-content
$(function () {
	$(document).tooltip({
		content: function () {
			if($(this).parents(".k-grid").length > 0 || $(this).parents(".k-filter-menu").length > 0){
				// do not show tooltip
				return
			}
			return $(this).prop('title');
		},
		position: {
			my: "center bottom-5",
			at: "center top",
		  }
	});
});
